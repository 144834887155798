<template>
    <div>
      <div v-if="!showExam">
          <a-button type="primary" class="add-com-btn" @click="edit=false;addCompany={};visible=true">添加驾校</a-button>
        <a-table :columns="columns" :data-source="companyList" :scroll="{ x: 1000, y: 600 }" :pagination="false">
            <label for="" slot="examSpotIsOpen" slot-scope="text,item" @click="changSpot(item)">
              <a-switch  checked-children="开" un-checked-children="关" :checked="item.examSpotIsOpen"  />
            </label>
            <label for="" slot="examBusIsOpen" slot-scope="text,item" @click="changBus(item)">
              <a-switch  checked-children="开" un-checked-children="关" :checked="item.examBusIsOpen" />
            </label>
            <a slot="action" slot-scope="text,item">
                <!-- <router-link :to="{name:'考场管理',params:{companyId:item.companyId}}" class="dis">考场管理</router-link> -->
                <a-button type="link" @click="selectSchool(item)">考场管理</a-button>  
                <a-divider type="vertical" />
                <a-button class="dis" type="link" style="color:#888" @click="editM(item)">编辑</a-button>
                <a-divider type="vertical" />
                <a @click.prevent="getBusDate(item)" class="dis">班车有效期</a>
                <a-divider type="vertical" />
                <a-button class="dis" type="link" style="color:#888" @click="companyId=item.companyId;addShow=true">添加老板</a-button>
                <a-divider type="vertical" />
                <a-button type="link" @click="dowloadPic(item)">二维码</a-button>
            </a>
        </a-table>
        <div class="pagination">
          <a-pagination v-model="condition.pageNum" :pageSize="condition.pageSize" @change="changePage" :total="total" show-less-items />  
        </div> 
        <!-- 添加公司 -->
        <a-modal v-model="visible" :title="edit?'编辑驾校':'添加驾校'" @cancel="visible=false" @ok="addMethod" okText="确定" cancelText="取消">
          <p class="flex">
            <label class="type-name">驾校名</label> 
            <a-input class="flex1" v-model="addCompany.companyName" placeholder="驾校名" />
          </p>
          <p class="flex">
            <label class="type-name">备注</label>
            <a-textarea class="flex1" v-model="addCompany.remark" placeholder="备注信息" />
          </p>
        </a-modal>
        <!-- 添加用户 -->
        <add-user v-if="addShow" :companyId="companyId" :edit="false" @addSuccess="getUser();addShow=false" @close="addShow=false" />
        <!-- 班车有效期设置 -->
        <a-modal v-model="busDateShow" title="班车有效期" okText="确定" cancelText="取消" @ok="setBusDate">
          <div>
            班车有效期<br><br>
            <a-date-picker v-model="busDate" format="YYYY-MM-DD" />
          </div>
        </a-modal>
      </div>
      <!-- 考场管理 -->
      <exam-manage v-if="showExam" :company="curSchool" @back="showExam=false" />
    </div>
</template>
<script>
import addUser from './components/addUser'
import ExamManage from './components/ExamManage'
const columns = [
  { title: '驾校', width: 100, dataIndex: 'companyName', key: 'companyName', fixed: 'left' },
  { title: '备注', width: 100, dataIndex: 'remark', key: 'remark' },
  { title: '老板', dataIndex: 'bossName', key: 'bossName', width: 150 },
  { title: '账号', dataIndex: 'bossAccount', key: 'bossAccount', width: 150 },
  { title: '收费', dataIndex: 'examSpotIsOpen', key: 'examSpotIsOpen', width: 150,scopedSlots: { customRender: 'examSpotIsOpen' } },
  { title: '班车', dataIndex: 'examBusIsOpen', key: 'examBusIsOpen', width: 150,scopedSlots: { customRender: 'examBusIsOpen' } },
  { title: '操作', dataIndex: 'address', key: 'address',width: 350,scopedSlots: { customRender: 'action' }, },
];
export default {
  components:{
    addUser,
    ExamManage
  },
  data() {
    return {
      addShow:false,
      companyId:'',
      condition:{
        pageNum:1,
        pageSize:this.pageSize
      },
      addCompany:{
        isStop:true
      },
      visible:false,
      companyList:[],
      columns,
      edit:false,
      total:0,
      busDateShow:false,
      busDate:'',
      curSchool:{},
      showExam:false
    };
  },
  mounted(){
    
    this.getCompany()
  },
  methods:{
    base64ToBlob(code) {
        let parts = code.split(';base64,');
        let contentType = parts[0].split(':')[1];
        let raw = window.atob(parts[1]);
        let rawLength = raw.length;
        let uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {type: contentType});
    },
    dowloadPic(item){
      this.$post(this.$url.下载二维码, {
        companyId:item.companyId
      }).then(data => {
        let aLink = document.createElement('a');
        let blob = this.base64ToBlob("data:image/jpeg;base64,"+data.data.qrcode); 
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true);
        aLink.download = "智慧考场.jpg";
        aLink.href = URL.createObjectURL(blob);
        aLink.click()
      }).catch(() => {
        this.$tips('生成失败','请重新操作！','error')
      });
    },
    selectSchool(item){
      this.curSchool = item
      this.showExam = true
    },
    getUser(){
      this.getCompany()
    },
    //获取班车有效期
    getBusDate(item){
      this.companyId = item.companyId;
      this.$post(this.$url.获取班车有效期, {
        companyId:item.companyId
      }).then(data => {
        this.busDateShow = true;
        this.busDate = data.data.list.length?data.data.list[0].expireDay:'';
      }).catch(() => {
        this.$tips('添加失败','请重新创建驾校！','error')
      });
    },
    //设置班车有效期
    setBusDate(item){
      this.busDate = this.$moment(this.busDate).format("YYYY-MM-DD")
      this.$post(this.$url.设置班车有效期, {
        companyId: this.companyId,
        expireDay: this.busDate
      }).then(data => {
        this.busDateShow = false;
        this.$tips('更新成功','班车有效期更新成功！','success')
      }).catch(() => {
        this.$tips('添加失败','请重新创建驾校！','error')
      });
    },
    changePage(page){
      this.condition.pageNum = page ;
      this.getCompany()
    },
    //设置收费权限
    changSpot(item){
      this.$confirm({
        title:'是否确认操作？',
        content:`是否确认${item.examSpotIsOpen?'关闭':'打开'}收费权限？`,
        onOk:()=>{
          this.$post(this.$url.收费权限, {
            companyId:item.companyId,
            examSpotIsOpen:!item.examSpotIsOpen
          }).then(data => {
            this.visible = false;
            this.$tips('编辑成功','驾校编辑成功，请继续使用','success')
            this.getCompany()
          }).catch(() => {
            this.$tips('添加失败','请重新创建驾校！','error')
          });
        }
      })
    },
    //设置班车权限
    changBus(item){
      this.$confirm({
        title:'是否确认操作？',
        content:`是否确认${item.examSpotIsOpen?'关闭':'打开'}班车权限？`,
        onOk:()=>{
          this.$post(this.$url.班车权限, {
            companyId:item.companyId,
            examBusIsOpen:!item.examBusIsOpen
          }).then(data => {
            this.visible = false;
            this.$tips('编辑成功','驾校编辑成功，请继续使用','success')
            this.getCompany()
          }).catch(() => {
            //this.$tips('添加失败','请重新创建驾校！','error')
          });
        }
      })
    },
    editM(item){
      this.addCompany = Object.assign({},item)
      this.edit = true
      this.visible = true
    },
    editMethod(){
      this.$post(this.$url.编辑公司, this.addCompany).then(data => {
        this.visible = false;
        this.$tips('编辑成功','驾校编辑成功，请继续使用','success')
        this.getCompany()
      }).catch(() => {
        this.$tips('添加失败','请重新创建驾校！','error')
      });
    },
    addMethod(){
      if(this.edit){
        this.editMethod()
        return
      }
      this.$post(this.$url.添加公司, this.addCompany).then(data => {
        this.visible = false;
        this.$tips('添加成功','驾校创建成功，继续添加考场','success')
        this.getCompany()
      }).catch(() => {
        this.$tips('添加失败','请重新创建驾校！','error')
      });
    },
    getCompany(){
      this.$post(this.$url.公司列表, this.condition).then(data => {
        this.companyList = data.data.list
        this.total = data.data.total
      }).catch(() => {
          
      });
    }
  }
};
</script>
<style scoped>

</style>