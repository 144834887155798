<template>
  <div>
    <!-- 考场列表 -->
    <a-button type="link" @click="$emit('back')">驾校管理</a-button> >
    <span>“ {{company.companyName}} ” 考场</span> 
    <a-button type="link" class="add-com-btn" @click="visible=true">添加考场</a-button>
    <a-table :columns="columns" :data-source="examList" :scroll="{ x: 1000, y: 600 }">
        <span slot="subjectId" slot-scope="item">{{item=='2'?'二':'三'}}</span>
        <span slot="isOpen" slot-scope="item">{{item?'正常':'停用'}}</span>
        <a slot="action" slot-scope="index,item">
          <a-button type="primary" class="dis" @click="editExam(item)">编辑</a-button>
          <!-- <router-link to="/user" class="dis">用户管理</router-link> -->
          <a-button>删除</a-button>
        </a>
    </a-table>
    <!-- 添加考场 -->
    <a-modal v-model="visible" title="添加考场" @cancel="visible=false" @ok="addMethod" okText="确定" cancelText="取消">
      <p class="flex">
        <label class="type-name">考场全名</label> 
        <a-input class="flex1" v-model="addExam.examSpotName" placeholder="考场名" />
      </p>
      <p class="flex">
        <label class="type-name">简称</label> 
        <a-input class="flex1" v-model="addExam.shortName" placeholder="简称" />
      </p>
      <p class="flex">
        <label class="type-name">备注</label>
        <a-textarea class="flex1" v-model="addExam.remark" placeholder="备注信息" />
      </p>
      <p class="flex">
        <label class="type-name">科目</label> 
        <a-checkbox :checked="addExam.subjectId==2" @click="choiceKM(2)">科二</a-checkbox>
        <a-checkbox :checked="addExam.subjectId==3" @click="choiceKM(3)">科三</a-checkbox>
      </p>
      <p>
        <label class="type-name">是否启用</label>
        <a-switch :checked="addExam.isOpen" @click="addExam.isOpen = !addExam.isOpen" />
      </p>
      <p>
        <label class="type-name">有效期</label>
        <a-date-picker v-model="addExam.expireDay" />
      </p>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  { title: '考场名称', width: 200, dataIndex: 'examSpotName', key: 'examSpotName'},
  { title: '简称', width: 150, dataIndex: 'shortName', key: 'shortName' },
  { title: '科目', dataIndex: 'subjectId', key: 'subjectId', width: 70,scopedSlots: { customRender: 'subjectId' } },
  { title: '备注', dataIndex: 'remark', key: 'remark', width: 150 },
  { title: '状态', dataIndex: 'isOpen', key: 'isOpen', width: 70 ,scopedSlots: { customRender: 'isOpen' }},
  { title: '到期时间', dataIndex: 'expireDay', key: 'expireDay', width: 150 },
  
  //{ title: '二维码', dataIndex: 'address', key: '4', width: 150 },
  //{ title: '注册日期', dataIndex: 'address', key: '5', width: 150 },
  //{ title: '欠费状态', dataIndex: 'address', key: '6', width: 150 },
  //{ title: '密码重置', dataIndex: 'address', key: '7', width: 150 },
  { title: '操作', dataIndex: 'address', key: '8',scopedSlots: { customRender: 'action' }, },
  
];

const data = [];
for (let i = 0; i < 100; i++) {
  data.push({
    key: i,
    name: `驾校名 ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}

export default {
  name:'exammanage',
  props:{
    company:{
      type:Object,
      default:{}
    }
  },
  data() {
    return {
      companyId:'',
      addExam:{
        isOpen:true,
        subjectId:2
      },
      visible:false,
      examList:[],
      columns,
      edit:false
    };
  },
  mounted(){
    
    this.companyId = this.company.companyId
    this.getExam()
  },
  methods:{
    editExam(item){
      this.edit = true;
      this.visible = true;
      this.addExam = Object.assign({},item)
      this.addExam.id = item.id;
      console.log('item',item)
    },
    choiceKM(i){
      this.addExam.subjectId = i
    },
    editMethod(){
      this.$post(this.$url.编辑考场, this.addExam).then(data => {
        this.visible = false;
        this.$tips('编辑成功','考场编辑成功！','success')
        this.getExam()
      }).catch(() => {
        this.$tips('编辑失败','请重新提交修改信息！','error')
      });
    },
    addMethod(){
      this.addExam.expireDay = this.$moment(this.addExam.expireDay).format("YYYY-MM-DD")
      this.addExam.companyId = this.company.companyId
      if(this.edit){
        this.editMethod();
        return
      }
      this.$post(this.$url.添加考场, this.addExam).then(data => {
        this.visible = false;
        this.$tips('添加成功','考场添加成功！','success')
        this.getExam()
      }).catch(() => {
        this.$tips('添加失败','请重新创建驾校！','error')
      });
    },
    getExam(){
      this.$post(this.$url.考场列表, {
        companyId:this.companyId
      }).then(data => {
        this.examList = data.data.list
      }).catch(() => {
          
      });
    }
  }
};
</script>
<style scoped>

</style>